import { axios } from '@/utils/request'

export function getTopicsByTag(paramMap) {
  return axios({
    url: '/community/tag/' + paramMap.name,
    method: 'get',
    params: {
      page: paramMap.page,
      size: paramMap.size
    }
  })
}
